import { DOCUMENT } from '@angular/common';
import { inject, RendererFactory2 } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { WindowService } from '../services';

export const bodyLockGuard = (): CanActivateFn => {
    return () => {
        const windowService = inject(WindowService);
        const document = inject(DOCUMENT);
        const rendererFactory2 = inject(RendererFactory2);
        const renderer2 = rendererFactory2.createRenderer(null, null);
        renderer2.setStyle(document.body, 'padding-right', `${windowService.scrollbarWidth}px`);
        renderer2.addClass(document.body, 'lock');
        return true;
    };
};
