import { Routes, Route } from '@angular/router';
import { ContainerComponent } from './components/container/container.component';
import { bodyLockGuard, bodyUnlockGuard } from '../guards';

export const popupShell = (path: string, shellRoutes: Routes, outlet = 'modal'): Route => ({
    path: path,
    component: ContainerComponent,
    outlet: outlet,
    children: shellRoutes,
    canActivate: [bodyLockGuard()],
    canDeactivate: [bodyUnlockGuard()],
});
