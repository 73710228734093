import { DOCUMENT } from '@angular/common';
import { inject, RendererFactory2 } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

export function bodyUnlockGuard<T>(): CanDeactivateFn<T> {
    return () => {
        const document = inject(DOCUMENT);
        const rendererFactory2 = inject(RendererFactory2);
        const renderer2 = rendererFactory2.createRenderer(null, null);
        renderer2.removeStyle(document.body, 'padding-right');
        renderer2.removeClass(document.body, 'lock');
        return true;
    };
}
